import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private afAuth: AngularFireAuth, private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    var promise = new Promise<boolean>((resolve, reject) => {
      this.afAuth.authState.subscribe(x => {
        if (x) {
          resolve(true)
          return;
        }
        else {
          resolve(false)
          this.router.navigate(["login"], {
            queryParams: { returnUrl: state.url }
          });
        }
        return;
      });
    });
    return promise;
  }
}
