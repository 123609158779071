import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { auth } from 'firebase';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userEmail = "";
  userPassword = "";
  signInIsInProgress = false;
  returnUrl = "";
  constructor(private afAuth: AngularFireAuth, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.queryParams.subscribe(x => {
      this.returnUrl = x["returnUrl"];
    });
  }

  ngOnInit() {
  }
  onKey($event) {
    if ($event.code === "Enter" || $event.code === "NumpadEnter")
      this.login()

  }
  login() {
    this.signInIsInProgress = true;
    var promise = new Promise(async (resolve, reject) => {
      var success = false;
      try {
        var ctx = await this.afAuth.auth.signInWithEmailAndPassword(
          this.userEmail,
          this.userPassword
        );
        success = true;


        await this.redirect();
        setTimeout(() => {
          this.signInIsInProgress = false;
        }, 300)

      } catch (err) {
        alert(err);
        this.signInIsInProgress = false;
      }
    })
    return promise;

  }
  async redirect() {
    if (this.returnUrl === "/" || !this.returnUrl) {
      return this.router.navigateByUrl("/home");

    } else return this.router.navigateByUrl(this.returnUrl);
  }
  loginWithGoogle() {
    this.signInIsInProgress = true;
    this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider()).then(x => {
      this.signInIsInProgress = false;
      this.redirect();
    });
  }
}
